<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <h2>Elementary</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="ESchool"
            :rules="[vv.init(this.ESchool),vv.required()]"
            label="Name of School"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="EYear"
            :rules="[vv.init(this.EYear),vv.requiredNumber(),vv.digit_range(1930,this.currentYear)]"
            type="number"
            :min="1930"
            :max="currentYear"
            label="Year Graduated"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="EHonors"
            label="Honors/Awards"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>High School</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="HSchool"
            :rules="[vv.init(this.HSchool),vv.required()]"
            label="Name of School"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="HYear"
            :rules="[vv.init(this.HYear),vv.requiredNumber(),vv.digit_range(1930,this.currentYear)]"
            type="number"
            :min="1930"
            :max="currentYear"
            label="Year Graduated"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="HHonors"
            label="Honors/Awards"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Senior High School</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="SSchool"
            label="Name of School"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="SYear"
            type="number"
            :min="1930"
            :max="currentYear"
            label="Year Graduated"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="SHonors"
            label="Honors/Awards"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="Strand"
            label="Strand Completed"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="SHSAve"
            type="number"
            :min="0"
            :max="99"
            label="SHS Average Grade"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Vocational/Technical</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="VSchool"
            label="Name of School"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="VYear"
            type="number"
            :min="1930"
            :max="currentYear"
            label="Year Graduated"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="VHonors"
            label="Honors/Awards"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>College</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="CSchool"
            label="Name of School"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="CYear"
            type="number"
            :min="1930"
            :max="currentYear"
            label="Year Graduated"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="CHonors"
            label="Honors/Awards"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="SMost"
            :rules="[vv.init(this.SMost),vv.required()]"
            label="What subject do you like most?"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="SMostWhy"
            :rules="[vv.init(this.SMostWhy),vv.required()]"
            label="Why?"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="SLeast"
            :rules="[vv.init(this.SLeast),vv.required()]"
            label="What subject do you like least?"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="SLeastWhy"
            :rules="[vv.init(this.SLeastWhy),vv.required()]"
            label="Why?"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="Support"
            :rules="[vv.init(this.Support),vv.required()]"
            label="Who supports you in your studies?"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="Membership"
            label="Membership in clubs, fraternities, civic and religious organizations (please indicate your position, if any):"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4 text--white"
            @click="savechanges"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Your changes have been successfully saved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import vldn from "@/js/validation";
export default {
  name: "Educ",
  data: () => ({
    snackbar: false,
    timeout: 2000,
    valid: true,
    formTitle: "",
    currentYear: new Date().getFullYear(),
    ESchool: "",
    EYear: "",
    EHonors: "",
    HSchool: "",
    HYear: "",
    HHonors: "",
    SSchool: "",
    SYear: "",
    SHonors: "",
    Strand: "",
    SHSAve: "",
    VSchool: "",
    VYear: "",
    VHonors: "",
    CSchool: "",
    CYear: "",
    CHonors: "",
    SMost: "",
    SMostWhy: "",
    SLeast: "",
    SLeastWhy: "",
    Support: "",
    Membership: "",
    vv: vldn.methods,
  }),
  mounted() {
    this.$api.methods.init();
    this.getEduc();
  },
  methods: {
    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "guid/updateeduc",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            ESchool: this.ESchool,
            EYear: this.EYear,
            EHonors: this.EHonors,
            HSchool: this.HSchool,
            HYear: this.HYear,
            HHonors: this.HHonors,
            SSchool: this.SSchool,
            SYear: this.SYear,
            SHonors: this.SHonors,
            Strand: this.Strand,
            SHSAve: this.SHSAve,
            VSchool: this.VSchool,
            VYear: this.VYear,
            VHonors: this.VHonors,
            CSchool: this.CSchool,
            CYear: this.CYear,
            CHonors: this.CHonors,
            SMost: this.SMost,
            SMostWhy: this.SMostWhy,
            SLeast: this.SLeast,
            SLeastWhy: this.SLeastWhy,
            Support: this.Support,
            Membership: this.Membership,
          },
        })
          .then((response) => {
            if (response) {
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 500) {
                //
              }
            }
          });
      }
    },
    getEduc() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "guid/educ",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.ESchool = response.data[0].ESchool;
            this.EYear = response.data[0].EYear;
            this.EHonors = response.data[0].EHonors;
            this.HSchool = response.data[0].HSchool;
            this.HYear = response.data[0].HYear;
            this.HHonors = response.data[0].HHonors;
            this.SSchool = response.data[0].SSchool;
            this.SYear = response.data[0].SYear;
            this.SHonors = response.data[0].SHonors;
            this.Strand = response.data[0].Strand;
            this.SHSAve = response.data[0].SHSAve;
            this.VSchool = response.data[0].VSchool;
            this.VYear = response.data[0].VYear;
            this.VHonors = response.data[0].VHonors;
            this.CSchool = response.data[0].CSchool;
            this.CYear = response.data[0].CYear;
            this.CHonors = response.data[0].CHonors;
            this.SMost = response.data[0].SMost;
            this.SMostWhy = response.data[0].SMostWhy;
            this.SLeast = response.data[0].SLeast;
            this.SLeastWhy = response.data[0].SLeastWhy;
            this.Support = response.data[0].Support;
            this.Membership = response.data[0].Membership;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
  },
};
</script>

<style>
.container {
  max-width: 80%;
}
</style>